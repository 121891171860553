<!--
 * @Descripttion: 续费
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-08 16:10:34
-->
<template>
	<div>
		<van-nav-bar title="短信充值" left-arrow @click-left="$router.push('/mine')" />
		<div class="org">
			<img :src="org.orgPic">
			<div>
				<h1 class="van-ellipsis">{{ org.departName }}</h1>
				<p>短信剩余条数：{{ this.surplusCount }}</p>
			</div>
		</div>
		<div class="pad-row">
			<h3>增值实用服务</h3>
			<ul class="renew">
				<li v-for="(item) in list" :key="item.id" @click="onSelected(item)" :class="selectedItem.id ==item.id ? 'active': ''">
					<h3>{{ item.serviceName }}</h3>
					<p>
						<template v-if="showDiscountPrice"><span class="f35">￥</span><span>{{ item.currentPrice}}</span></template>
						<template v-else><span class="f35 fw">￥</span><span>{{ item.originalPrice}}</span></template>
						<del class="gray2" v-if="showDiscountPrice">￥{{ item.originalPrice }}</del>
					</p>
					<p class="red">{{ item.serviceDesc }}</p>
				</li>
			</ul>
			<h3 class="mar-t">服务介绍</h3>
			<p class="sms-desc">短信一键发送功能，是在抽取评标专家参与评标的过程中，更为方便的对评标专家进行评标通知。告别了繁复的通知流程，将评标时间地点等信息，高效快捷地传达信息，操作便捷。</p>
		</div>
		<div class="yd">
			<ul>
				<li>
					<i class="icon-sms-1"></i>
					<p>简单操作</p>
					<p>点击按钮一键发送评标相关信息</p>
				</li>
				<li>
					<i class="icon-sms-2"></i>
					<p>传达快捷</p>
					<p>短信通知评审专家 告别传统通知方式</p>
				</li>
				<li>
					<i class="icon-sms-3"></i>
					<p>信息安全</p>
					<p>安全信息发送 告别恶意骚扰</p>
				</li>
			</ul>
		</div>
		<div class="h2"></div>
		<div class="fixed-bottom pad25">
			<button class="btn goldBg w-100" @click="onRecharge()">立即充值</button>
		</div>

		<van-dialog v-model="dialogVisible" title="充值服务-请微信扫码联系客服人员" :show-confirm-button="showConfirmButton" :show-cancel-button="showCancelButton"
			cancel-button-text="关闭">
			<div align="center">
				<img width="200" height="200" align="middle" src="https://hiweke.bidsoon.cn/sys/common/static/icon/icon_kefu01.png" />
			</div>		
		</van-dialog>

	</div>
	
</template>

<script>
	import global from "@/common/global"
	export default {
		data() {
			return {
				queryParam: {
					orgId: "",
				},
				org: {
					surplusDays: 0,
				},
				list: [],
				selectedItem: {
					id: '',
					originalPrice: 0, //原价
					currentPrice: 0, //现价
					rechargePrice: 0, //充值价格--最终价格
				},
				showDiscountPrice: false,
				/** 剩余短信数量 */
				surplusCount: 0,
				workweixin: false,				//企业微信
				openUserid:	"",
				dialogVisible: false,
				showConfirmButton: false,
				showCancelButton: true
			}
		},
		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			if (this.$ls.get('edCurrentOrgExternal')) {
				this.queryParam.orgId = this.$ls.get('edCurrentOrgExternal').id;
				this.org = this.$ls.get('edCurrentOrgExternal');
				if (!this.org.orgPic)
					this.org.orgPic = '/static/img/icon/default-logo.png'
				console.log(this.org);
			}
			this.surplusCount = this.$ls.get('edCurrentOrgExternal').smsSurplusTimes || 0;
			console.log(this.surplusCount);
			//查询短信充值产品
			this.querySmsProduct()
		},
		methods: {
			//检索短信充值产品
			querySmsProduct: function() {
				let __this = this
				this.$ajax({
					method: 'post',
					url: '/gateway/ed/sms/queryOnShelfSmsProduct?openid=' + (this.workweixin?this.openUserid:this.$ls.get("openid")),
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
				}).then(res => {
					if (res.status == "200" && res.data.retCode == "0") {
						if (res.data.products) {
							for (let ii = 0; ii < res.data.products.length; ii++) {
								let item = {}
								item.id = res.data.products[ii].id
								item.serviceName = res.data.products[ii].productName
								item.currentPrice = res.data.products[ii].currentPrice
								item.originalPrice = res.data.products[ii].originalPrice
								item.serviceDesc = res.data.products[ii].serviceDesc
								item.rechargePrice = res.data.products[ii].currentPrice
								__this.list.push(item)
							}
						}
					}
				});
			},

			//充值成功
			onRecharge() {
				if (this.selectedItem.id == '') {
					this.$toast.fail('请选择充值产品！')
					return;
				}
				if (this.selectedItem.rechargePrice <= 0) {
					this.$toast.fail('产品价格不能小于等于0！')
					return;
				}
				if (this.selectedItem.rechargePrice == '') {
					this.$toast.fail('产品价格不能为空！')
					return;
				}
				//如果是企业微信，联系管理员
				if(this.workweixin){
					this.dialogVisible = true;
				}else{
					//个人微信
					/////支付演示:一分钱
					// this.selectedItem.rechargePrice = 0.01
					//开通服务商品
					this.doUnifiedOrder();
				}

			},
			//选中服务
			onSelected(item) {
				this.selectedItem = item;
			},
			//充值成功，开通服务
			onOpenService() {
				let data = {};
				data.orgId = this.queryParam.orgId;
				data.productId = this.selectedItem.id;
				data.rechargeAmount = this.selectedItem.rechargePrice; //首次充值金额
				this.$ajax({
					method: "post",
					url: "/gateway/ed/sms/afterChargeSuccessed",
					headers: {
						"X-Access-Token": this.$ls.get("token"),
					},
					params: {
						openid: (this.workweixin?this.openUserid:this.$ls.get("openid"))
					},
					data: data
				}).then((res) => {
					if (res.status == "200" && res.data.retCode == "0") {
						this.$toast.success("充值成功！")
						this.$router.push({
							name: 'mine'
						});
					} else {
						this.$toast.fail("保存出错！")
					}
				});
			},
			//服务商品
			doUnifiedOrder() {
				let dataObj = {
					body: this.selectedItem.serviceName,
					openid: this.workweixin?this.openUserid:this.$ls.get("openid"),
					totalFee: this.selectedItem.rechargePrice,
					appId: global.appid
				}
				this.$ajax
					.post("/gateway/integral/wxPay/doUnifiedOrder", dataObj)
					.then((res) => {
						if (res.data.success) {
							this.payObj = res.data.result;
							this.onBridgeReady();
						} else {
							alert(res.data.message);
							this.$toast.fail(res.data.message)
						}
					})
			},
			//调用JSAPI顾客微信支付
			onBridgeReady() {
				let _this = this;
				WeixinJSBridge.invoke(
					'getBrandWCPayRequest', {
						"appId": global.appid, //公众号名称，由商户传入
						"timeStamp": _this.payObj.timeStamp, //时间戳，自1970年以来的秒数
						"nonceStr": _this.payObj.nonceStr, //随机串
						"package": _this.payObj.package, //统一下单接口返回的prepay_id参数值
						"signType": _this.payObj.signType, //微信签名方式：
						"paySign": _this.payObj.paySign //微信签名
					},
					function(res) {
						if (res.err_msg == "get_brand_wcpay_request:ok") {
							// 使用以上方式判断前端返回,微信团队郑重提示：
							//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
							// alert("谢谢您的赞赏，嗨微客平台将为您提供更优质的服务！");
							//充值成功，开通服务
							_this.onOpenService();
						}
					});
			},
		},
	}
</script>

<style scoped>
	.btn {
		color: #1E1E1E;
		font-weight: bold;
	}

	.renew {
		width: 100%;
		display: flex;
		overflow: scroll;
	}

	.renew>li {
		flex-shrink: 0;
		width: 45%;
		border: 1px solid #E6E6E6;
		text-align: center;
		border-radius: 0.2rem;
		padding: 0.2rem;
		margin-left: 2%;
	}

	.renew>li:first-child {
		margin-left: 0;
	}

	.renew>li>p>span:nth-child(2) {
		font-size: 0.8rem;
		line-height: 1.12rem;
		font-weight: bold;
	}

	.renew>li>p>del {
		font-size: 0.35rem;
		display: inline-block;
		margin-left: 0.15rem;
	}

	.renew>li.active {
		border: 1px solid #E5B96F;
		color: #E5B96F;
		background: #FBF6E8;
	}

	.renew>li>h3 {
		color: #1E1E1E;
		font-size: .37rem;
		line-height: .53rem;
	}

	.mar-l2 {
		width: calc(100% - 2.4rem);
	}

	.w15 {
		width: 1.5rem;
		height: 1.5rem;
	}

	.org {
		padding: 0.5rem .75rem;
		background: linear-gradient(123deg, #FFEFCC 0%, #E5B96F 100%);
		display: flex;
	}

	.org>img {
		width: 1.28rem;
		height: 1.28rem;
		object-fit: cover;
	}

	.org>div {
		width: calc(100% - 1.08rem);
		padding-left: .32rem;
	}

	.org>div>p {
		font-size: .32rem;
		line-height: .45rem;
		margin-top: 0.13rem;
		color: #CC0015;
	}

	.pad-row>h3 {
		font-size: .48rem;
		line-height: .67rem;
		margin: .53rem 0;
	}

	.red {
		font-size: .32rem;
		line-height: .45rem;
		margin-top: .11rem;
	}

	.sms-desc {
		font-size: .37rem;
		line-height: .64rem;
		color: #737373;
	}

	.yd {
		margin-top: .43rem;
		padding: 0 0.32rem;
	}

	.yd ul {
		display: flex;
	}

	.yd li {
		width: 33.33%;
		text-align: center;
		padding: 0 .21rem;
	}

	.yd li i {
		width: .85rem;
		height: .85rem;
	}

	.yd li p {
		margin-top: .21rem;
	}

	.yd li p:first-of-type {
		color: #E5B96F;
		font-size: .37rem;
		line-height: .53rem;
	}

	.yd li p:last-of-type {
		font-size: .29rem;
		line-height: .43rem;
		color: #A6A6A6;
	}
</style>
